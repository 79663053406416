/*- Custom style -*/

// -- Put custom style under this point -- //
.highlighter-rouge {
  background-color: aliceblue;
}

.tags {
  font-size: 14px;
  color: #333333;
  background-color: rgb(189, 207, 223);
  display: inline-block;
  padding: 0.25em 0.4em;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

pre {
  overflow: auto;
}